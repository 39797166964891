import { Link } from 'react-router-dom';
import { Globe, MousePointer } from 'react-feather';
import './footer.scss';

type Props = {
  showLinks?: boolean;
};

export default function Footer(props: Props) {
  return (
    <footer className="footer">
      <div className="copyrights">
        VV-MEDMAT-62356 03/22
        <br />
        &copy; 2022 Takeda Pharmaceutical Company Limited. All rights reserved.
        Takeda and the Takeda Logo are registered trademarks of Takeda
        Pharmaceutical Company Limited.
      </div>
      {props.showLinks && (
        <div className="links">
          <a
            href="https://clinicaltrials.takeda.com/"
            target="_blank"
            className="button primary"
            rel="noreferrer"
          >
            <Globe />
            <span>
              Learn&nbsp;more&nbsp;abouts Takeda&nbsp;Clinical&nbsp;Trials
            </span>
          </a>
          <a
            href="https://forms.gle/yM5Rz6yx9FXWNQJw8"
            target="_blank"
            className="button secondary"
            rel="noreferrer"
          >
            <MousePointer />
            Submit&nbsp;a&nbsp;Medical Information&nbsp;Request
          </a>
        </div>
      )}
    </footer>
  );
}
