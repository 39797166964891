import './assets/styles/global.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/app';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import { getConfig } from './modules/config';

const gaTrackingId = getConfig('REACT_APP_GA_ID');
gaTrackingId &&
  ReactGA.initialize(gaTrackingId, {
    debug: getConfig('REACT_APP_GA_DEBUG') === 'true',
  });

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
