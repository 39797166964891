import './app.scss';
import cn from 'classnames';
import { usePathClasses } from '../../modules/routing';
import Gate from '../gate';
import { useLocation } from 'react-router-dom';
import Footer from '../footer';
import { useEffect } from 'react';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import { getConfig } from '../../modules/config';

export default function App() {
  const gtmId = getConfig('REACT_APP_GTM_ID');
  if (gtmId) {
    TagManager.initialize({ gtmId });
  }

  let location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, [location]);

  const pathClass = usePathClasses();

  return (
    <div className={cn(['app', pathClass])}>
      <Gate />
      <Footer showLinks={false} />
    </div>
  );
}
