import './gate.scss';

import logo from '../../assets/images/logo.svg';
import { Plus } from 'react-feather';
import { recordEvent } from '../../modules/stats';
import { MouseEventHandler } from 'react';

export default function Gate() {
  return (
    <div className="gate">
      <div className="main">
        <img src={logo} alt="Logo" className="logo" />
        <h1 className="title">
          Welcome to the
          <br />
          Takeda Virtual Exhibit
        </h1>
        <div className="links">
          <a
            href="https://lymphoma.takedaoncologyvirtualexperience.com"
            className="button bare"
            onClick={linkOnClick}
          >
            Oncology <Plus className="icon" color="#ee1100" />
          </a>
          <a
            href="https://medicalaffairstransplant.com"
            className="button bare"
            onClick={linkOnClick}
          >
            Transplant <Plus className="icon" color="#ee1100" />
          </a>
        </div>
      </div>

      <div className="disclaimer">
        The information on this website is intended for Healthcare Professionals
        Only
      </div>
    </div>
  );
}

const linkOnClick: MouseEventHandler<HTMLAnchorElement> = function (e) {
  e.preventDefault();
  recordEvent('Menu links', e.currentTarget.innerText);
  window.open(e.currentTarget.href);
};