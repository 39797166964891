import { useLocation } from 'react-router';

export function usePathClasses() {
  const location = useLocation();
  if (location.pathname.includes('index.html')) return 'path--index';

  const segments = location.pathname.split('/').filter((s) => s !== '');
  const classes: string[] = [];
  let className = 'path--';
  segments.forEach((element, index) => {
    className += element;
    if (index + 1 !== segments.length) {
      className += '-';
    }
    classes.push(className);
  });

  classes.length === 0 && classes.push('path--index');

  return classes.join(' ');
}
